.infographic-1,
.infographic-2,
.infographic-3 {
  border: 1px solid #e1e5f0;
  background-color: #f7f9fd; //#f5f5f5;
  padding: 10px;
  // align-items: stretch;

  // img {
  // }
}
