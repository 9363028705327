@import "../../../../assets/styles/variables.scss";

.director-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f9fd;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  h3 {
    font-size: 1.1em;
  }

  p {
    font-size: 0.9em;
  }

  .director-role {
    color: #626262;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 719px) {
    border-radius: 8px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.director-image {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media screen and (max-width: 719px) {
      border-radius: 8px;
    }
  }

  .director-links {
    position: absolute;
    bottom: -16px;
    width: 100%;
    text-align: center;
    a {
      display: inline-block;
      background-color: $xerLightBlue;
      color: #ffffff;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 25px;
      text-align: center;
      font-size: 16px !important;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 30px;
  }
}
