@import "../../assets/styles/variables.scss";

.product-details {
  .product-logo {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 140px;

      @media screen and (max-width: 575px) {
        max-width: 300px;
      }
    }
  }
  .product-subscribe-link {
  }
  .product-standard-features {
    font-size: 0.8em;

    ul {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .product-feature {
    font-size: 0.8em;
    text-align: center;
    //line-height: 0.85em;
    border: 1px solid $xerDarkBlue;
    border-radius: 5px;
    padding: 5px 8px;
    margin-bottom: 8px;
    cursor: default;
  }

  .product-video {
    .product-video-title {
      font-size: 0.8em;
      text-align: center;

      @media screen and (max-width: 991px) {
        font-size: 1em;
      }
    }
  }
}

.product-standard {
  .product-feature-hightlighted {
    border-color: $xerLightBlue;
  }
}
.product-plus {
  .product-feature-hightlighted {
    border-color: $xerLightGreen;
  }
}
.product-professional {
  .product-feature-hightlighted {
    border-color: $xerRed;
  }
}
