.common-field {
  .text-field {
    height: 200px;
  }

  .check-box-field {
    //display: table;

    input {
      height: 17px;
      width: 17px;
    }

    .check-box-label {
      position: relative;
      top: 2px;
    }

    .check-box-error {
      position: relative;
      top: -10px;
    }
  }
}
