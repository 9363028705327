@import "./variables.scss";

@mixin buttonStyle(
  $color,
  $font-weight,
  $bg-color,
  $hover-bg-color,
  $padding,
  $border,
  $hover-color,
  $hover-border
) {
  color: $color !important;
  font-weight: $font-weight;
  background-color: $bg-color !important;
  padding: $padding !important;
  border: $border;
  border-radius: 5px;
  transition: 0.3s all;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-family: $condensedFontFamily;
  font-size: 0.85em;

  &:hover {
    background-color: $hover-bg-color !important;
    color: $hover-color !important;
    border: $hover-border !important;
  }
}

.sw-transparent-button {
  @include buttonStyle(
    #fff,
    unset,
    unset,
    #fff,
    3px 12px 3px 12px,
    1px solid #fff,
    black,
    1px solid white
  );
}

.sw-std-btn,
.sw-btn-primary {
  @include buttonStyle(
    #ffffff,
    unset,
    $xerLightBlue,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    $xerLightBlue,
    1px solid $xerLightBlue
  );
}

.sw-btn-secondary {
  @include buttonStyle(
    $xerLightBlue,
    unset,
    #ffffff,
    $xerLightBlue,
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    #fff,
    1px solid $xerLightBlue
  );
}

.sw-btn-std-white {
  @include buttonStyle(
    $xerLightBlue,
    unset,
    #ffffff,
    $xerLightBlue,
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    #fff,
    1px solid $xerLightBlue
  );
}

.sw-btn-std-blue {
  @include buttonStyle(
    #ffffff,
    unset,
    $xerLightBlue,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    $xerLightBlue,
    1px solid $xerLightBlue
  );
}

.sw-btn-xer-blue {
  @include buttonStyle(
    #ffffff,
    unset,
    $xerLightBlue,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    $xerLightBlue,
    1px solid $xerLightBlue
  );
}

.sw-btn-xer-green {
  @include buttonStyle(
    #ffffff,
    unset,
    $xerGreen,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid $xerGreen,
    $xerGreen,
    1px solid $xerGreen
  );
}

.sw-btn-xer-red {
  @include buttonStyle(
    #ffffff,
    unset,
    $xerRed,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid $xerRed,
    $xerRed,
    1px solid $xerRed
  );
}

.sw-btn-alt-xer-blue {
  @include buttonStyle(
    $xerLightBlue,
    unset,
    rgba(255, 255, 255, 0.8),
    $xerLightBlue,
    3px 12px 3px 12px,
    1px solid $xerLightBlue,
    #ffffff,
    1px solid $xerLightBlue
  );
}

.sw-btn-alt-xer-green {
  @include buttonStyle(
    $xerGreen,
    unset,
    rgba(255, 255, 255, 0.8),
    $xerGreen,
    3px 12px 3px 12px,
    1px solid $xerGreen,
    #ffffff,
    1px solid $xerGreen
  );
}

.sw-btn-alt-xer-red {
  @include buttonStyle(
    $xerRed,
    unset,
    rgba(255, 255, 255, 0.8),
    $xerRed,
    3px 12px 3px 12px,
    1px solid $xerRed,
    #ffffff,
    1px solid $xerRed
  );
}

.sw-btn-std-grey {
  @include buttonStyle(
    #ffffff,
    unset,
    #999999,
    rgba(255, 255, 255, 0.8),
    3px 12px 3px 12px,
    1px solid #999999,
    #999999,
    1px solid #999999
  );
}

.sw-link {
  cursor: pointer !important;
  text-decoration: underline;
  border: none !important;
}

button.text-link {
  font-size: 1em;
  text-align: left;
  color: #007bff;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
}

button.text-link:hover {
  text-decoration: underline !important;
}
