@import "./variables.scss";

/*
* Client Specific Sass file
* Adjust colors based on client's theme
* Solvable 8.30.2019 -rcnet
*/

/* Start Client Nav Bar Colors */
.navbar-bg {
  background-color: $navBarBgColor; // Nav Bar color
}
.navbar-default {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 0.8em;

  .navbar-nav > li > a,
  .navbar-nav > li > a:focus {
    color: $navBarItemColor;
  }

  .navbar-nav > li {
    text-transform: uppercase;
  }

  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    background-color: $navBarItemBgColor; // Hover item color
    border-radius: 3px;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    color: $navBarItemHoverColor !important;
  }

  .navbar-toggler {
    border: none;
    color: $navBarItemColor;
  }

  .dropdown-menu {
    background-color: $navBarDropDownMenuBgColor; // Drop down menu color

    a {
      color: $navBarItemColor !important;
    }
  }
}

/* End Client Nav Bar Colors */

.social-icon {
  display: inline-block;
  background-color: $xerLightBlue;
  color: #ffffff;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px !important;
}

a {
  color: #007bff;
}

.xer-form {
  label {
    font-size: 0.85em;
    margin-bottom: 0.375rem !important;
  }

  .form-control {
    font-size: 0.85em;
  }

  .form-group {
    margin-bottom: 0.85rem !important;
  }
}

.rnd-img {
  border-radius: 8px;
}

.box-list {
  list-style-type: none;
  padding-inline-start: 0px;
  display: flex;
  justify-content: center;
  align-content: stretch;
  margin-top: 15px;
  margin-bottom: 0;

  li {
    display: flex;
    border: 1px solid $xerDarkBlue;
    border-radius: 8px;
    padding: 5px 8px;
    margin: 5px;
    align-items: center;

    span {
      text-align: center;
      display: block;
    }
  }
}

.nav-tabs {
  .nav-item {
    cursor: pointer;
  }
}

.news-img {
  max-width: 400px;
}
