.read-more-less {
  transition: all 0.1s linear;
  display: block;
}

.read-more-less-hidden {
  display: none;
}

.read-more-less-visuallyhidden {
  opacity: 0;
}
