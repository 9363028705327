@import "../../../../assets/styles/variables.scss";

.password-error-style {
  ul {
    list-style-type: none;
    font-weight: bold;

    .success {
      color: #3a7ec9;
    }
  }
}
