@import "../../../../../assets/styles/variables.scss";

.mobile-timeline-slider {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  .carousel .slide {
    background-color: #f7f9fd !important;
  }
  .mobile-timeline-item {
    height: 100%;
    display: flex;
    align-items: center;

    .mobile-timeline-content {
      flex: 1;

      .mobile-timeline-label {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .control-prev::before {
    border-right-color: $xerLightBlue !important;
  }
  .control-next::before {
    border-left-color: $xerLightBlue !important;
  }

  @media screen and (max-width: 599px) {
    max-width: 345px;
  }
}
