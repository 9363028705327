@import "../../../../assets/styles/variables.scss";

.top-info-bar {
  height: 34px;
  line-height: 34px;
  background-color: $xerDarkBlue;
  color: #f8f8f8;
  font-size: 0.75em;
  font-family: $condensedFontFamily;

  a {
    color: #f8f8f8 !important;
  }

  @media (max-width: 669px) {
    .location {
      display: none;
    }
  }

  @media (max-width: 369px) {
    font-size: 0.8em;
  }

  @media (max-width: 344px) {
    font-size: 0.7em;
  }
}
