@import "../../../../../../assets/styles/variables.scss";

.endorsements-slider {
  .carousel .slide {
    background-color: #ffffff !important;
  }
  .endorsement-item {
    //height: 180px;
    height: 100%;
    display: flex;
    align-items: center;

    .endorsement-content {
      //height: 180px;

      .endorsement {
        text-align: left;
        margin-bottom: 15px;
        text-align: justify;
      }
      .client-name {
        font-style: italic;
        color: $xerLightBlue;
      }
    }
  }

  .control-prev::before {
    border-right-color: $xerLightBlue !important;
  }
  .control-next::before {
    border-left-color: $xerLightBlue !important;
  }
}
