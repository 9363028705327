.faqs {
  .header-section {
    h5 {
      font-size: 0.9em;
      i {
        font-size: 0.8em;
      }
    }
  }

  .card-body {
    font-size: 0.8em;
  }
}
