@import "../../../../../../assets/styles/variables.scss";

.subscribe-products {
  .product {
    .product-info {
      display: flex;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      border-bottom: 2px solid #999999;

      .product-details {
        flex-grow: 1;
        width: 100%;

        .product-img {
          width: 100%;
          text-align: center;

          img {
            height: 115px;
          }
        }

        .name {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;

          font-size: 0.8em;
          font-weight: bold;
          text-align: center;

          margin-top: 15px;
          margin-bottom: 15px;

          color: $xerDarkBlue;
        }

        .annual-cost {
          color: $xerDarkBlue;
          text-align: center;
          font-size: 1.35em;
          font-weight: 600;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .subscribe-btns {
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 15px;

          a {
            display: inline-block;
            width: 100%;
          }
        }
      }

      .link {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        margin-bottom: 15px;
        margin-top: auto;
      }
    }
    .xer-blue {
      border-bottom: 2px solid $xerLightBlue;
    }
    .xer-green {
      border-bottom: 2px solid $xerGreen;
    }
    .xer-red {
      border-bottom: 2px solid $xerRed;
    }
  }
}

@media (max-width: 959px) {
  .product:nth-of-type(3) {
    margin-bottom: 0 !important;
  }
}
