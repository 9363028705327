@mixin dialog-button {
  font-family: roboto;
  font-size: 13.6px;
}

.dialog-primary {
  @include dialog-button;
  background-color: #0099cb;
  border: 1px solid #0099cb;

  &:hover {
    color: #0099cb;
    background-color: white;
    border: 1px solid #0099cb;
  }
}

.dialog-secondary {
  @include dialog-button;
  background-color: white;
  border: 1px solid #0099cb;
  color: #0099cb;

  &:hover {
    background-color: #0099cb;
    color: white;
    border: 1px solid white;
  }
}
