.sw-bg-std-white {
  background-color: #ffffff;
}

.sw-bg-std-grey {
  background-color: #f5f5f5;
}

.grey-gradient {
  background: rgb(247, 249, 253);
  background: linear-gradient(
    180deg,
    rgba(247, 249, 253, 1) 0%,
    rgba(0, 153, 203, 0.25) 100%
  );
}

.grey-texture-1 {
  background-color: #f7f9fd;
  background-image: url("/images/backgrounds/grey-bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.grey-texture-2 {
  background-color: #f7f9fd;
  background-image: url("/images/backgrounds/grey-bg-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.test-bg {
  background-color: #ffffff;
  background-image: url("/images/backgrounds/test-bg-3.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

// .sw-bg-cover-img {
//   background-image: url(/images/background.jpg);
//   background-attachment: fixed;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

// .sw-bg-cover-img-alt {
//   background-image: url(/images/background.jpg);
//   background-size: cover;
//   background-position: center;

//   @media only screen and (max-width: 767px) {
//     height: 8vh;
//   }

//   @media only screen and (min-width: 768px) {
//     height: 12vh;
//   }
// }
