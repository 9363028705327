.trial-xertransfer {
  display: flex;
  width: 100%;
  min-height: 800px;
  align-items: center;

  background-image: url("/images/trial-and-subscribe/trial-bg-std.jpg");
  background-size: cover;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;

  position: relative;

  .row {
    height: 100%;
    align-items: center;

    .shadow-box {
      background-color: rgba(199, 45, 43, 0.55);
      padding: 25px;
      border-radius: 25px;

      p {
        text-align: center !important;
        color: #ffffff !important;
      }
    }

    .trial-logo {
      position: absolute;
      top: -90px;
      left: -80px;
      width: 220px;

      @media screen and (max-width: 768px) {
        position: relative;
        top: auto;
        left: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}

// .trial-xertransfer {
//   display: flex;
//   width: 100%;
//   min-height: 625px;
//   align-items: center;

//   background-image: url("/images/trial-and-subscribe/trial-bg.jpg");
//   background-size: cover;
//   //   background-size: contain;
//   //   background-repeat: no-repeat;
//   //   background-position: center;

//   .row {
//     height: 100%;
//     align-items: center;

//     .shadow-box {
//       background-color: rgba(116, 116, 116, 0.5);
//       padding: 25px;
//       border-radius: 25px;

//       p {
//         text-align: center !important;
//         color: #ffffff !important;
//       }
//     }
//   }
// }
