@import "../../../../assets/styles/variables.scss";

.banner-item {
  background-size: cover;
  background-position: center;
  //height: 30vh;
  height: 500px;

  .banner-content {
    max-width: 1140px;
    margin: 0 auto;
    //height: 30vh;
    height: 500px;

    h1,
    h2 {
      color: #ffffff;
      font-family: $headingFontFamily;
      font-weight: 300;
    }

    h1 {
      font-size: 1.75em;
    }
    h2 {
      font-size: 1.5em;
    }

    p {
      color: #ffffff;
      font-family: $headingFontFamily;
      font-weight: 300;
      font-size: 1.3em;
      margin: 0;
    }
  }
}

.banner-default {
  .banner-content {
    h1,
    h2 {
      color: $xerDarkBlue !important;
    }

    p {
      color: $xerDarkBlue !important;
      font-weight: 400 !important;
    }
  }
}

.banner-01 {
  background-image: url("/images/home/banner/01.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/01.jpg");
  }
}
.banner-02 {
  background-image: url("/images/home/banner/02.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/02.jpg");
  }
}
.banner-03 {
  background-image: url("/images/home/banner/03.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/03.jpg");
  }
}
.banner-04 {
  background-image: url("/images/home/banner/04.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/04.jpg");
  }
}
.banner-05 {
  background-image: url("/images/home/banner/05.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/05.jpg");
  }
}
.banner-06 {
  background-image: url("/images/home/banner/06.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/06.jpg");
  }
}
.banner-07 {
  background-image: url("/images/home/banner/07.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/07.jpg");
  }
}
.banner-08 {
  background-image: url("/images/home/banner/08.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/08.jpg");
  }
}
.banner-09 {
  background-image: url("/images/home/banner/09.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/09.jpg");
  }
}
.banner-10 {
  background-image: url("/images/home/banner/10.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/10.jpg");
  }
}
.banner-11 {
  background-image: url("/images/home/banner/11.jpg");

  @media screen and (max-width: 414px) {
    background-image: url("/images/home/banner/mobile/11.jpg");
  }
}
.banner-12 {
  background-image: url("/images/home/banner/default.jpg");

  .banner-content {
    h1,
    h2 {
      color: $xerDarkBlue !important;
    }

    p {
      color: $xerDarkBlue !important;
    }
  }
}
