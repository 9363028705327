.tc {
  background-color: #f2f2f2;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;

  .logo {
    max-height: 16px;
  }
}
