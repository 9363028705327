@import "./variables.scss";

.error-text {
  color: $swErrorColor;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.content-section {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $headingFontFamily;
    color: $headingFontColor;
    font-weight: 300;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 25px;

    .alt {
      color: $xerLightBlue;
    }
  }

  h1::after {
    background: none repeat scroll 0 0 Red;
    background: linear-gradient(90deg, $xerLightBlue 50%, $xerDarkBlue 100%);
    bottom: -10px;
    content: "";
    display: block;
    height: 2px;
    position: relative;
    width: 114px;
  }

  h1.text-center::after {
    left: calc(50% - 57px);
  }

  p {
    color: $paragraphColor;
    font-size: 0.85em;
    text-align: justify;
  }

  li {
    color: $paragraphColor;
    font-size: 0.85em;
    text-align: justify;
  }

  ul.two-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @media only screen and (max-width: 500px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }

  .featured-text {
    font-size: 1.1em;
  }
}
