@import "../../../../../../assets/styles/variables.scss";

.clients-slider {
  .carousel {
    .slide {
      background-color: #ffffff !important;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        max-height: 180px;
        display: block;
      }
    }

    .slider-wrapper {
      height: 200px !important;
    }
  }

  .control-prev::before {
    border-right-color: $xerLightBlue !important;
  }
  .control-next::before {
    border-left-color: $xerLightBlue !important;
  }
}
