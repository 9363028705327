@import "../../assets/styles/variables.scss";

footer {
  //background: $footerBgColor;
  background-color: #162658; //#0a0a0a;

  color: #ffffff;
  // -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  // position: absolute;
  // bottom: 0;
  width: 100%;
  padding: 45px 0 0 0;

  margin-top: auto;

  .container-fluid {
    max-width: 1140px;

    .col-wrap {
      display: flex;
      justify-content: space-between;

      .column-1 {
        max-width: 20%;
        padding-right: 5%;

        h3 {
          border-bottom: 2px solid #162658 !important;

          img {
            display: block;
            max-height: 17px;
          }
        }

        @media screen and (max-width: 670px) {
          max-width: 100%;
          padding-right: 0;
        }
      }
      .column-2 {
        padding-right: 5%;
        min-width: 40%;

        @media screen and (max-width: 767px) {
          max-width: auto;
        }

        h3 {
          @media screen and (max-width: 670px) {
            margin-top: 30px;
          }
        }
        @media screen and (max-width: 670px) {
          padding-right: 0;
        }
      }

      .column-3 {
        div {
          i {
            display: inline-block;
            width: 15px;
            text-align: center;
            margin-right: 4px;
          }
          a {
            color: #9e9e9e;
          }
          line-height: 1.8em;
        }

        .indent {
          padding-left: 22px;
        }

        h3 {
          @media screen and (max-width: 670px) {
            margin-top: 30px;
          }
        }
      }

      .column-1,
      .column-2,
      .column-3 {
        //padding-right: 50px;
        font-family: $condensedFontFamily;
        font-size: 0.8em;

        h3 {
          display: inline-block;
          padding-bottom: 10px;
          border-bottom: 2px solid $xerLightBlue;
          font-size: 1.3em;
        }

        ul {
          margin-bottom: 0;
          padding: 0;

          li {
            list-style: none;
            a {
              color: #ffffff;
            }
            line-height: 1.8em;

            ul {
              padding: 0 0 0 11px;

              li {
                a {
                  color: #9e9e9e;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 670px) {
        flex-flow: column;
      }
    }
  }
  .copyright-wrap {
    background-color: $xerDarkBlue;
    margin-top: 30px;
    .copyright {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      font-family: $condensedFontFamily;
      font-size: 0.8em;
      a {
        color: #ffffff;
      }

      @media screen and (max-width: 545px) {
        text-align: center;
        flex-flow: column;

        div:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
}
