$headingFontColor: #1b2f69;
$headingFontFamily: "Roboto Condensed", sans-serif;
$defaultFontFamily: "Roboto", sans-serif;
$condensedFontFamily: "Roboto Condensed", sans-serif;
$paragraphColor: #333333;

$swPrimaryColor: #1377a2;
$swSecondaryColor: #6d4da0;

$swBtnPrimaryColor: #1377a2;
$swBtnPrimaryHoverColor: #168dc0;
$swBtnSecondaryColor: #179ea3;
$swBtnSecondaryHoverColor: #1db7bd;

$swAltColor: #179ea3;
$swErrorColor: #ad0c0c;

$navBarBgColor: #eeeeee;
$navBarItemBgColor: #0099cb;
$navBarItemColor: #1b2f69;
$navBarItemHoverColor: #1b2f69;
$navBarDropDownMenuBgColor: #0099cb;

$xerDarkBlue: #1b2f69;
$xerLightBlue: #0099cb;
$xerGreen: #017457;
$xerLightGreen: #b8c533;
$xerRed: #c8302b;
