@import "../../../../assets/styles/variables.scss";

.home-slider {
  .carousel .slide {
    background-color: #ffffff !important;
  }
  .home-slider-item {
    background-size: cover;
    background-position: center;
    height: 280px;
    margin-left: 7.5px;
    margin-right: 7.5px;

    .slider-content {
      max-width: 1140px;
      margin: 0 auto;
      height: 280px;

      p {
        width: 100%;
        color: #ffffff;
        font-family: $headingFontFamily;
        font-weight: 300;
        font-size: 1em;
        margin: 0;
      }
    }

    @media (max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
